import { mapboxAccessToken } from '../config/configMaps';

const MAPBOX_DIRECTIONS_API_DRIVING = 'https://api.mapbox.com/directions/v5/mapbox/driving';

export const geoDistance = ({ point1, point2 }) => {
  const path = `/${point1.lng},${point1.lat};${point2.lng},${point2.lat}`;
  const queryParams = {
    alternatives: 'true',
    geometries: 'geojson',
    language: 'en',
    overview: 'full',
    steps: 'true',
    access_token: mapboxAccessToken,
  };
  const queryString = `?${new URLSearchParams(queryParams).toString()}`;
  const uri = `${MAPBOX_DIRECTIONS_API_DRIVING}${path}${queryString}`;
  return window.fetch(uri, { method: 'GET' }).then(res => {
    return res.json();
  });
};
