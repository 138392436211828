import React from 'react';
import { bool } from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { propTypes } from '../../util/types';
import { resolveLatestProcessName, getProcess } from '../../transactions/transaction';

import css from './OrderBreakdown.module.css';

const LineItemTotalPrice = props => {
  const { transaction, isProvider, intl } = props;
  const processName = resolveLatestProcessName(transaction?.attributes?.processName);
  if (!processName) {
    return null;
  }
  const process = getProcess(processName);
  const isCompleted = process.isCompleted(transaction?.attributes?.lastTransition);
  const isRefunded = process.isRefunded(transaction?.attributes?.lastTransition);
  const isPriceOffered = process.isPriceOffered(transaction?.attributes?.lastTransition);

  let providerTotalMessageId = 'OrderBreakdown.providerTotalDefault';
  if (isCompleted) {
    providerTotalMessageId = 'OrderBreakdown.providerTotalReceived';
  } else if (isRefunded) {
    providerTotalMessageId = 'OrderBreakdown.providerTotalRefunded';
  }

  const totalLabel = isProvider ? (
    <FormattedMessage id={providerTotalMessageId} />
  ) : (
    <FormattedMessage id="OrderBreakdown.total" />
  );

  let emissionMessage = <FormattedMessage id='OrderBreakdown.emission'/>;
  let deliveryPriceMessage = <FormattedMessage id='OrderBreakdown.deliveryPrice'/>;
  let soilPriceMessage = <FormattedMessage id='OrderBreakdown.soilPrice'/>;

  const totalPrice = isProvider
    ? transaction.attributes.payoutTotal
    : transaction.attributes.payinTotal;
  const formattedTotalPrice = formatMoney(intl, totalPrice);
  if (isProvider && transaction.attributes.lineItems[0].deliveryPrice) {
    const formattedDeliveryPrice = formatMoney(intl, transaction.attributes.lineItems[0].deliveryPrice) + " x " + transaction.attributes.lineItems[0].quantity;
    const formattedSoilPrice = formatMoney(intl, transaction.attributes.lineItems[0].soilPrice) + " x " + transaction.attributes.lineItems[0].quantity;
    const emissionString = (transaction.attributes.lineItems[0].unitEmission).toFixed(1) + " kg";

    return (
      <>
        <hr className={css.totalDivider} />
        <div className={css.lineItem}>
          <div className={css.itemLabel}>{deliveryPriceMessage}</div>
          <div className={css.itemValue}>{formattedDeliveryPrice}</div>
        </div>
        <div className={css.lineItem}>
          <div className={css.itemLabel}>{soilPriceMessage}</div>
          <div className={css.itemValue}>{formattedSoilPrice}</div>
        </div>
        <hr className={css.totalDivider} />
        <div className={css.lineItemTotal}>
          <div className={css.totalLabel}>{totalLabel}</div>
          <div className={css.totalPrice}>{formattedTotalPrice}</div>
        </div>
        <div className={css.lineItemTotal}>
          <div className={css.totalLabel}>{emissionMessage}</div>
          <div className={css.totalPrice}>{emissionString}</div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <hr className={css.totalDivider} />
        <div className={css.lineItemTotal}>
          <div className={css.totalLabel}>{totalLabel}</div>
          <div className={css.totalPrice}>{formattedTotalPrice}</div>
        </div>
      </>
    );
  }
};

LineItemTotalPrice.propTypes = {
  transaction: propTypes.transaction.isRequired,
  isProvider: bool.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemTotalPrice;
